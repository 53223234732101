<!--
 * @Description: 多选字典
 * @Author: zhang zhen
 * @Date: 2023-04-17 21:07:39
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-22 16:15:27
 * @FilePath: /page-sass/src/components/plugins/JMultiSelectTag.vue
-->
<template>
  <div>
    <a-select style="width: 100%" :value="arrayValue" @change="onChange" :disabled="disabled" mode="multiple" :placeholder="placeholder"
      :getPopupContainer="getParentContainer" optionFilterProp="children" :filterOption="filterOption" :maxTagCount="maxTagCount"
      allowClear :showArrow="true" v-if="optionType == 'select'">
      <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
      <a-select-option v-for="(item, index) in dictOptions" :key="index" :value="item.dictKey">
        <a-checkbox :checked="arrayValue.includes(item.dictKey)">
          <span style="width: 100%" :title="item.dictValue">
            {{ item.dictValue }}
          </span>
        </a-checkbox>
      </a-select-option>
    </a-select>
    <a-checkbox-group :value="arrayValue" @change="onChange" v-else>
      <a-checkbox v-for="(item, index) in dictOptions" :key="index" :value="item.dictKey">
        {{ item.dictValue }}
      </a-checkbox>
    </a-checkbox-group>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'MultiSelectTag',
  props: {
    dictCode: String,
    placeholder: String,
    disabled: Boolean,
    value: String,
    options: Array,
    spliter: {
      type: String,
      required: false,
      default: ','
    },
    maxTagCount: {
      type: Number,
      default: 2
    },
    popContainer: {
      type: String,
      default: '',
      required: false
    },
    optionType: {
      type: String,
      default: 'select'
    }
  },
  data() {
    return {
      dictOptions: [],
      tagType: '',
      arrayValue: !this.value ? [] : this.value.split(this.spliter)
    }
  },
  watch: {
    options: function (val) {
      this.setCurrentDictOptions(val)
    },
    dictCode: {
      immediate: true,
      handler() {
        this.initDictData()
      }
    },
    value(val) {
      if (!val) {
        this.arrayValue = []
      } else {
        this.arrayValue = this.value.split(this.spliter)
      }
    }
  },
  methods: {
    initDictData() {
      getAction('/basic/dict/queryByDictNo', { dictNo: this.dictCode }).then(res => {
        if (res.success) {
          this.dictOptions = res.data
        }
      })
    },
    onChange(selectedValue) {
      //   this.$forceUpdate()
      this.$emit('change', selectedValue.join(this.spliter))
    },
    setCurrentDictOptions(dictOptions) {
      this.dictOptions = dictOptions
    },
    getCurrentDictOptions() {
      return this.dictOptions
    },
    getParentContainer(node) {
      if (!this.popContainer) {
        return node.parentNode
      } else {
        return document.querySelector(this.popContainer)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>
<style lang="less">
.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #f9f9f9 !important;

  .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 12px !important;
  }

  .ant-checkbox-checked {
    display: none;
  }
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon {
  display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}

.ant-select-selection--multiple .ant-select-selection__rendered > ul > li  {
  height: 30px;
  line-height: 28px;
}

.ant-select-disabled {
  .ant-select-selection__choice .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.33) !important;
  }
}
</style>
